<div class="fondo">
    <div class="row g-1 p-0 p-md-4">
        <div class="col-12 text-center mb-5">
           <!-- <p><img src="../../../../assets/imgs/logo.png" style="margin-bottom: 20px;" width="150"></p>-->
          
            <h1>Panel Administrador</h1>
            <span>Acceso al sistema.</span>

        </div>
        <div class="container">
            <div class="col-12">
                <div class="mb-2">
                    <label class="form-label">Usuario</label>
                    <input type="documento" [(ngModel)]="usuario" class="form-control form-control-lg">
                </div>
            </div>
            <div class="col-12">
                <div class="mb-2">
                    <div class="form-label">
                        <span class="d-flex justify-content-between align-items-center">
                            Contraseña
                          <!--  <a class="text-primary" href="authentication/password-reset">¿Olvidaste tu contraseña?</a>-->
                        </span>
                    </div>
                    <input type="password" [(ngModel)]="password" class="form-control form-control-lg">
                </div>
            </div>
            <div class="col-12">
                <!--  <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
           <label class="form-check-label" for="flexCheckDefault">
                Remember me
            </label>
        </div>-->
            </div>
            <div class="col-12 text-center mt-4">
                <button type="button" (click)="login()" class="btn btn-lg btn-block btn-dark lift text-uppercase"
                    [disabled]="!usuario || !password">INGRESAR</button>
            </div>
        </div>
    </div>
</div>