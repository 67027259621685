import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MetodosComponent } from './metodos/metodos.component';
import { ComponentsModule } from 'src/app/components/components.module';
import { ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [ MetodosComponent],
  imports: [
    CommonModule,
    ComponentsModule,
    ReactiveFormsModule
  ],
  exports:[ MetodosComponent ]
})
export class MetodosdepagoModule { }
