import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListComponent } from './list/list.component';
import { DataTablesModule } from 'angular-datatables';
import { ComponentsModule } from 'src/app/components/components.module';


@NgModule({
  declarations: [ListComponent],
  imports: [
    CommonModule,
    DataTablesModule,
    ComponentsModule
  ],
  exports: [
    ListComponent
  ],

})
export class UsersModule { }
