<div class="wrapper">
    <app-sidebar></app-sidebar>

    <!-- Page Content  -->
    <div id="content-header">
        <app-header></app-header>
        <div id="content">
            <h3 class="text-center mb-3">CREAR NUEVA RIFA</h3><br/>
            <form [formGroup]="frmSave">
                <div class="row">
                    <div class="col">
                        <label class="form-label"><b>Premio: </b></label>
                        <textarea type="text" class="form-control" formControlName="premio"></textarea>
                    </div>
                    <div class="col">
                        <label class="form-label"><b>Descripción: </b></label>
                        <textarea type="text" class="form-control" formControlName="descripcion"></textarea>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col">
                        <label class="form-label"><b>Imagen: </b></label>
                        <input type="file" name="myImage" accept="image/*" id="file" #file (change)="onFileChange($event)" class="form-control" />
                    </div>
                    <div class="col">
                        <label class="form-label"><b>Cantidad de números: </b></label>
                        <input type="number" class="form-control" formControlName="cantnumeros" >
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col">
                        <label>
                            <input type="checkbox" (change)="onChange($event)"> Números free
                          </label>
                          
                          <div *ngIf="mostrarInput">
                            <label for="inputNumeros" style="margin-right: 5px;">Cantidad de números adicionales: </label>
                            <input type="number" id="inputNumeros" formControlName="cantnumerosfree" />
                          </div>
                    </div>
                    <div class="col">
                        <label class="form-label"><b>Fecha fin: </b></label>
                        <input type="datetime-local" class="form-control" formControlName="fechafin">
                    </div>
                </div>
               <!-- <div class="row mt-3">
                    <div class="col">
                        <label class="form-label"><b>Fecha inicio: </b></label>
                        <input type="date" class="form-control" formControlName="fechainicio">

                    </div>
             
                </div>-->

                <div class="row">
                    <div class="col text-center mt-5">
                        <button class="btn btn-success" [disabled]="!frmSave.valid" (click)="save()">GUARDAR</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>