import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RifasService {

  constructor(private http: HttpClient) { }

  getRifas() {
    return this.http.get(environment.apiUrl + 'getRifas');
  }

  getRifa() {
    return this.http.get(environment.apiUrl + 'getRifa');
  }

  getNumbers(id: any) {
    return this.http.get(environment.apiUrl + 'getNumbers/' + id);
  }

  newRifa(data: any) {
    return this.http.post(environment.apiUrl + 'newRifa', data);
  }

  editRifa(data: any, id_rifa: number) {
    return this.http.post(environment.apiUrl + 'editRifa/' + id_rifa, data);
  }

  closeRifa(id_rifa: number) {
    let data = {
      "id_rifa": id_rifa
    }
    return this.http.post(environment.apiUrl + 'closeRifa', data);
  }

  cancelNumbers(id_participante, numbers, id_rifa) {
    let data = {
      "id_participante": id_participante,
      "id_rifa": id_rifa,
      "numeros": numbers
    }
    return this.http.post(environment.apiUrl + 'cancelNumbers', data);
  }

  saveNum(num, id_rifa) {
    let data = {
      "id_rifa": id_rifa,
      "numero": num
    }
    return this.http.post(environment.apiUrl + 'saveNum', data);
  }

  payNumbers(id_participante, id_rifa) {
    let data = {
      "id_rifa": id_rifa,
      "id_participante": id_participante
    }
    return this.http.post(environment.apiUrl + 'payNumbers', data);
  }

  unPayNumbers(id_participante, id_rifa) {
    let data = {
      "id_rifa": id_rifa,
      "id_participante": id_participante
    }
    return this.http.post(environment.apiUrl + 'unPayNumbers', data);
  }

  getWinnersData(id_rifa: number) {
    return this.http.get(environment.apiUrl + 'getWinnersData/' + id_rifa);
  }

    //Agrega la imagen
    addImage(fileToUpload: File, id_rifa): Observable<boolean> {
      const endpoint = environment.apiUrl + 'rifas/addImage';
      const formData: FormData = new FormData();
      formData.append('id_rifa', JSON.stringify(id_rifa));
      formData.append('fileKey', fileToUpload, fileToUpload.name);
      return this.http
        .post(endpoint, formData, {
          headers: new HttpHeaders({
          })
        }).pipe(map(() => { return true; }));
    }
}
