import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from 'src/app/components/components.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ListaComponent } from './lista/lista.component';
import { NuevaComponent } from './nueva/nueva.component';
import { DataTablesModule } from 'angular-datatables';
import { ActivaComponent } from './activa/activa.component';




@NgModule({
  declarations: [ ListaComponent, NuevaComponent, ActivaComponent],
  imports: [
    CommonModule,
    ComponentsModule,
    ReactiveFormsModule,
    FormsModule,
    DataTablesModule
  ],
  exports: [ ListaComponent, NuevaComponent, ActivaComponent]
})
export class RifasModule { }
