import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SignInComponent } from './sign-in/sign-in.component';


@NgModule({
  declarations: [SignInComponent],
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule
  ],
  exports: [
    SignInComponent
  ]
})
export class AuthenticationModule { }
