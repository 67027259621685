import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})

export class AuthService {

  helper = new JwtHelperService();
  authenticated: boolean = false;

  constructor(private http: HttpClient, private router: Router) { }

  login(data: any) {
    return this.http.post(environment.apiUrl + 'login', data).pipe(
      map((res: any) => this.handleAuthResponse(res)),
      catchError((err) => of(false))
    );
  }

  public isLoggedOut() {
    return this.helper.isTokenExpired(localStorage.getItem('token')!); //return true or false
  }

  isLoggedIn() {
    return !this.isLoggedOut();
  }

  private handleAuthResponse(res: any) {
    if(res.token){
      const token = res.token;
      const decodedToken = this.helper.decodeToken(token);
      const expirationDate = this.helper.getTokenExpirationDate(token);
      localStorage.setItem('token', res.token);
      return true;
    }else{
      return false;
    }
    
  }


  logout() {
    localStorage.removeItem('token');
    this.router.navigateByUrl('authentication/sign-in');
  }

}
