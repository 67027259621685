<div class="wrapper">
    <app-sidebar></app-sidebar>

    <!-- Page Content  -->
    <div id="content-header">
        <app-header></app-header>
        <div id="content">

            <h3 class="text-center mb-3">RIFAS COMPLETADAS</h3>
            <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                class="table table-striped table-bordered row-border hover">
                <thead>
                    <tr>
                        <th>Premio</th>
                        <th>Fecha Inicio</th>
                        <th>Ganadores</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let r of rifas">
                        <td>{{r.premio}}</td>
                        <td>{{r.fechainicio | date: 'dd/MM/yyyy'}}</td>
                        <td class="text-center" title="Ver" (click)="openModal(modalver,r.id_rifa)"><i
                                class="fa-solid fa-eye" style="cursor:pointer"></i></td>
                    </tr>
                </tbody>
            </table>

        </div>
    </div>
</div>


<!--Numero ganador-->
<ng-template #modalganador let-modal>
    <div class="modal-header">
        <b>
            <div class="modal-title">GANADOR DE LA RIFA</div>
        </b>
        <button class="close" aria-label="close" type="button" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row" style="margin-top:10px;">
            <div class="col-sm text-center">
                <label class="form-label">Nombre</label>
                <input type="text" value="{{ganador?.nombre}}" class="form-control" readonly>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-sm text-center">
                <label class="form-label">Apellido</label>
                <input type="text" value="{{ganador?.apellido}}" class="form-control" readonly>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-sm text-center">
                <label class="form-label">Estado</label>
                <input type="text" value="{{ganador?.ciudad}}" class="form-control" readonly>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-sm text-center">
                <label class="form-label">WhatsApp</label>
                <input type="text" value="{{ganador?.whatsapp}}" class="form-control" readonly>
            </div>
        </div>
    </div>
</ng-template>


<!--Numero ganador-->
<ng-template #modalver let-modal>
    <div class="modal-header">
        <b>
            <div class="modal-title">GANADORES DE LA RIFA</div>
        </b>
        <button class="close" aria-label="close" type="button" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">Número</th>
                    <th scope="col">Nombre</th>
                    <th scope="col">Apellido</th>
                    <th scope="col">WhatsApp</th>
                    <th scope="col">Estado</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of ganadores">
                    <ng-container *ngIf="item.id_participante === null; then noganador; else ganador">
                    </ng-container>
                    <ng-template #noganador>
                        <td>{{item.numeroganador}}</td>
                        <td>---------</td>
                        <td>---------</td>
                        <td>------------</td>
                        <td>---------</td>
                    </ng-template>
                    <ng-template #ganador>
                        <td>{{item.numeroganador}}</td>
                        <td>{{item.nombre}}</td>
                        <td>{{item.apellido}}</td>
                        <td style="text-align: center; font-size: 18px; font-weight:600"><i class="fa-brands fa-whatsapp"
                            style="cursor:pointer" title="Enviar Mensaje" style="color:#000; cursor:pointer" (click)="whatsapp(item.whatsapp)"></i></td>
                        <td>{{item.ciudad}}</td>
                    </ng-template>
                  
                </tr>
            </tbody>
        </table>
    </div>
</ng-template>