import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RifasService } from 'src/app/services/rifas.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nueva',
  templateUrl: './nueva.component.html',
  styleUrls: ['./nueva.component.scss']
})
export class NuevaComponent implements OnInit {

  frmSave: FormGroup;
  fileToUpload: any;
  mostrarInput: boolean;
  numeros: number = 0;

  constructor(private formBuilder: FormBuilder, private rifasService: RifasService, private router: Router) { }

  ngOnInit(): void {
    //Formulario para guardar la rifa
    this.frmSave = this.formBuilder.group({
      premio: ["", Validators.required],
      descripcion: ["", Validators.required],
      fechainicio: [""],
      fechafin: [""],
      cantnumeros: ["", Validators.required],
      numerosfree: ['no'],
      cantnumerosfree: [null]
    });

  }

  onChange(event: any) {
    if (event.target.checked == true) {
      this.mostrarInput = true;
      this.frmSave.get('numerosfree').setValue('si');
    } else {
      this.mostrarInput = false;
      this.frmSave.get('numerosfree').setValue('no');
      this.frmSave.get('cantnumerosfree').setValue(null);
    }
  }

  save() {
    if (this.mostrarInput) {
      const cantnumeros = this.frmSave.value.cantnumeros;
      const cantnumerosfree = this.frmSave.value.cantnumerosfree;

      if (cantnumeros % (cantnumerosfree + 1) !== 0) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'La cantidad de números no es divisible por la cantidad de números adicionales',
        });
      } else {
        // Continúa con el proceso de guardar los datos
        this.rifasService.newRifa(this.frmSave.value).subscribe({
          next: resp => {
            console.log("esta es la resp", resp);
            if (resp) {
              Swal.fire({
                icon: 'success',
                title: 'Éxito',
                text: 'Rifa creada con éxito',
              });
              if (this.fileToUpload) {
                this.rifasService.addImage(this.fileToUpload, resp).subscribe((resp: any) => {
                  if (resp == true) {
                    console.log("Imagen cargada con éxito");
                    this.router.navigateByUrl('/rifas/activa');
                  }
                }, (error: any) => {
                  console.log("Error al subir la imagen: ", error);
                });
              }
            }
          },
          error: error => console.log("Error al crear la rifa: ", error)
        });
      }
    } else {
      // Continúa con el proceso de guardar los datos sin verificar divisibilidad
      this.rifasService.newRifa(this.frmSave.value).subscribe({
        next: resp => {
          console.log("esta es la resp", resp);
          if (resp) {
            Swal.fire({
              icon: 'success',
              title: 'Éxito',
              text: 'Rifa creada con éxito',
            });
            if (this.fileToUpload) {
              this.rifasService.addImage(this.fileToUpload, resp).subscribe((resp: any) => {
                if (resp == true) {
                  console.log("Imagen cargada con éxito");
                  this.router.navigateByUrl('/rifas/activa');
                }
              }, (error: any) => {
                console.log("Error al subir la imagen: ", error);
              });
            }
          }
        },
        error: error => console.log("Error al crear la rifa: ", error)
      });
    }
  }


  onFileChange(fileChangeEvent: any) {
    this.fileToUpload = fileChangeEvent.target.files[0];
  }

}

