import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IndexComponent } from './pages/index/index.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './helpers/auth.interceptor';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MyAccountModule } from './pages/my-account/my-account.module';
import { AuthenticationModule } from './pages/authentication/authentication.module';
import { DatePipe } from '@angular/common';
import { ComponentsModule } from './components/components.module';
import { UsersModule } from './pages/users/users.module';
import { RifasModule } from './pages/rifas/rifas.module';
import { MetodosdepagoModule } from './pages/metodosdepago/metodosdepago.module';

@NgModule({
  declarations: [
    AppComponent,
    IndexComponent,
   ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    MyAccountModule,
    UsersModule,
    AuthenticationModule,
    RifasModule,
    MetodosdepagoModule,
    ComponentsModule,
    HttpClientModule],
  providers: [{provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true}, DatePipe],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
]
})
export class AppModule { }
