<div class="wrapper">
    <app-sidebar></app-sidebar>

    <!-- Page Content  -->
    <div id="content-header">
        <app-header></app-header>
        <div id="content">
            <h3 class="text-center mb-3">METODOS DE PAGO</h3>
            <button type="button" class="btn btn-primary mb-3" (click)="openModalNew(modalagregar)">Agregar</button>
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">Nombre</th>
                        <th scope="col">Titulo</th>
                        <th scope="col">Descripcion</th>
                        <th scope="col">Titulo 2</th>
                        <th scope="col">Descripcion 2</th>
                        <th scope="col">Acción</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of metodos">
                        <th>{{item.nombre}}</th>
                        <th>{{item.titulo1}}</th>
                        <th>{{item.descripcion1}}</th>
                        <th>{{item.titulo2}}</th>
                        <th>{{item.descripcion2}}</th>
                        <th>
                          <i class="fa-solid fa-edit" style="cursor:pointer" title="Editar" style="color:#000; cursor:pointer" (click)="openEdit(modaleditar, item.id_metodopago, item.nombre, item.titulo1, item.titulo2, item.descripcion1, item.descripcion2)"></i>
                          <i class="fa-solid fa-trash" style="cursor:pointer" title="Eliminar" style="color:#000; margin-left:10px; cursor:pointer" (click)="delete(item.id_metodopago, item.nombre)"></i>
                        </th>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>


<!--Agrega un nuevo metodo de pago-->
<ng-template #modalagregar let-modal>
    <div class="modal-header">
        <b>
            <div class="modal-title">AGREGAR NUEVO METODO DE PAGO</div>
        </b>
        <button class="close" aria-label="close" type="button" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="frmNew">
            <div class="row">
                <div class="col">
                    <label class="form-label"><b>Nombre: </b></label>
                    <input type="text" class="form-control" formControlName="nombre">
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <label class="form-label"><b>Titulo1: </b></label>
                    <input type="text" class="form-control" formControlName="titulo1">

                </div>
                <div class="col">
                    <label class="form-label"><b>Descripcion1: </b></label>
                    <textarea type="text" class="form-control" formControlName="descripcion1"></textarea>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <label class="form-label"><b>Titulo2: </b></label>
                    <input type="text" class="form-control" formControlName="titulo2">
                </div>
                <div class="col">
                    <label class="form-label"><b>Descripcion2: </b></label>
                    <textarea type="text" class="form-control" formControlName="descripcion2"></textarea>
                </div>
            </div>
            <div class="row">
                <div class="col text-center mt-5">
                    <button class="btn btn-success" type="button" [disabled]="!frmNew.valid" (click)="new()">GUARDAR</button>
                </div>
            </div>
        </form>
    </div>

</ng-template>


<!--Editar Método de pago-->
<ng-template #modaleditar let-modal>
    <div class="modal-header">
        <b>
            <div class="modal-title">EDITAR METODO DE PAGO</div>
        </b>
        <button class="close" aria-label="close" type="button" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="frmEdit">
            <div class="row">
                <div class="col">
                    <label class="form-label"><b>Nombre: </b></label>
                    <input type="text" class="form-control" formControlName="nombre">
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <label class="form-label"><b>Titulo1: </b></label>
                    <input type="text" class="form-control" formControlName="titulo1">

                </div>
                <div class="col">
                    <label class="form-label"><b>Descripcion1: </b></label>
                    <textarea type="text" class="form-control" formControlName="descripcion1"></textarea>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <label class="form-label"><b>Titulo2: </b></label>
                    <input type="text" class="form-control" formControlName="titulo2">
                </div>
                <div class="col">
                    <label class="form-label"><b>Descripcion2: </b></label>
                    <textarea type="text" class="form-control" formControlName="descripcion2"></textarea>
                </div>
            </div>
            <div class="row">
                <div class="col text-center mt-5">
                    <button class="btn btn-success" [disabled]="!frmEdit.valid" (click)="edit()">GUARDAR</button>
                </div>
            </div>
        </form>
    </div>

</ng-template>