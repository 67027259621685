<div class="wrapper">
    <app-sidebar></app-sidebar>

    <!-- Page Content  -->
    <div id="content-header">
        <app-header></app-header>
        <div id="content">
            <h3 class="text-center mb-3">MI PERFIL</h3>
            <div class="row">
                <div class="col">
                    <div class="card mx-auto">
                        <div class="card-body text-center">
                            <h5 class="card-title text-center">Hola, {{usuario?.nombre}} {{usuario?.apellido}}</h5>
                            <p class="card-text text-center">{{usuario?.email}}</p>
                            <button type="button" class="btn btn-dark" (click)="openModal(changepassword)">Cambiar
                                Contraseña</button><br />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--Modal Cambiar Contraseña -->
<ng-template #changepassword let-modal>
    <form [formGroup]="frmChangePassword">
        <div class="modal-header">
            <b>
                <div class="modal-title">CAMBIAR CONTRASEÑA</div>
            </b>
            <button class="close" aria-label="close" type="button" (click)="modal.dismiss()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row" style="margin-top:10px;">
                <div class="col-sm text-center">
                    <label class="form-label">Nueva contraseña</label>
                    <input type="text" class="form-control" formControlName="newPassword">
                </div>
            </div>
            <div class="row" style="margin-top:10px;">
                <div class="col-sm text-center">
                    <label class="form-label">Confirmar nueva contraseña</label>
                    <input type="text" class="form-control" formControlName="confirmPassword">
                </div>
            </div>
        </div>
        <div class="modal-footer ">
            <button type="button" (click)="changePassword()" [disabled]="!frmChangePassword?.valid" class="mx-auto btn btn-success">Guardar</button>
        </div>
    </form>
</ng-template>