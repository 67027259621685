import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { RifasService } from 'src/app/services/rifas.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-lista',
  templateUrl: './lista.component.html',
  styleUrls: ['./lista.component.scss']
})
export class ListaComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  rifas: any;
  estadoRecarga: any;
  ganadores: any;

  constructor(private rifasService: RifasService, private modal: NgbModal) { }

  ngOnInit(): void {
    //Cargar DataTable
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 25,
      language: {
        url: '//cdn.datatables.net/plug-ins/1.11.3/i18n/es_es.json'
      }
    };
    this.rifasService.getRifas().subscribe((resp: any) => {
      this.rifas = resp;
       this.dtTrigger.next();
    }, (error: any) => { console.log("Error al obtener las recargas: ", error); });
  }

  openModal(modal: any, id_rifa: number) {

    this.rifasService.getWinnersData(id_rifa).subscribe((resp:any)=>{
      this.ganadores = resp;
    },(error:any)=> {console.log("Error al obtener los datos del ganador: ", error);})
    this.modal.open(modal, { size: "l" });
  }

  whatsapp(nro: any){
    window.open("https://wa.me/"+nro+"?", "_blank");
  }

}
