import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GeneralesService } from 'src/app/services/generales.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {

  usuario: any;
  password: any;
  confirmPassword: any;
  frmChangePassword: FormGroup;

  constructor(private generalesService: GeneralesService, private modal: NgbModal, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    //Formulario para cambiar contraseña
    this.frmChangePassword = this.formBuilder.group({
      newPassword: ["", Validators.compose([Validators.required, Validators.minLength(4), Validators.maxLength(30)])],
      confirmPassword: ["", Validators.compose([Validators.required, Validators.minLength(4), Validators.maxLength(30)])]
    });

    this.generalesService.getUserData().subscribe((resp: any) => {
      this.usuario = resp;
    }, (error: any) => { console.log("Error al obtener el usuario: ", error); })
  }

  openModal(modal: any) {
    this.modal.open(modal, { size: "l" });
    this.frmChangePassword.reset();
  }


  changePassword() {
      if (this.frmChangePassword.value.newPassword == this.frmChangePassword.value.confirmPassword) {
        this.generalesService.changePassword(this.frmChangePassword.value).subscribe((resp: any) => {
          if (resp === true) {
            Swal.fire({
              icon: 'success',
              title: 'Éxito',
              text: 'Contraseña actualizada',
            })
            this.modal.dismissAll();
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Se produjo un error',
            })
          }
        }, (error: any) => { console.log("Error al actualizar la contraseña: ", error); })
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Las contraseñas no coinciden',
        })
      }    
  }
}
