import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {

  usuario!: string;
  password!: string;

  constructor(private route: Router, private authService: AuthService) { }

  ngOnInit(): void {
    if (localStorage.getItem('auth') === '1') {
      this.route.navigateByUrl('index');
    }
  }

  login() {
    if (this.usuario != '' || this.password != '') {
      let data = { usuario: this.usuario, password: this.password };
      this.authService.login(data).subscribe((resp: any) => {
        if (resp == true) {
          this.route.navigateByUrl('index');
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Credenciales incorrectas',
          })
        }
      }, (error: any) => {
        console.log("Se produjo un error al iniciar sesion", error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Error de conexión con el servidor',
        })
      });
    }
  }
}
