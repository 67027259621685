import { Component, OnInit } from '@angular/core';
import { RifasService } from 'src/app/services/rifas.service';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {

  rifa: any;
  numbers: any;
  countValidTicketsD: number = 0; //disponible
  countValidTicketsA: number = 0; //apartado
  countValidTicketsP: number = 0; //pagado

  constructor(private rifasService: RifasService) { }


  ngOnInit(): void {
    this.rifasService.getRifa().subscribe({
      next: resp => {
        this.rifa = resp
        this.rifasService.getNumbers(this.rifa.id_rifa).subscribe({
          next: resp => {
            this.numbers = resp;
            this.numbers.forEach((element: any) => {
              if (element.id_estadonumero == 1) {
                this.countValidTicketsD = this.countValidTicketsD + 1;
              }
              if (element.id_estadonumero == 2) {
                this.countValidTicketsA = this.countValidTicketsA + 1;
              }
              if (element.id_estadonumero == 3) {
                this.countValidTicketsP = this.countValidTicketsP + 1;
              }
            });
           },
          error: error => console.log("Error al obtener los numeros: ", error),
        })
      },
      error: error => console.log("Error al obtener la rifa: ", error),
    });
  }


}
