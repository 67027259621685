import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GeneralesService } from 'src/app/services/generales.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-metodos',
  templateUrl: './metodos.component.html',
  styleUrls: ['./metodos.component.scss']
})
export class MetodosComponent implements OnInit {

  metodos: any;
  frmEdit: FormGroup;
  frmNew: FormGroup;

  constructor(private generalesService: GeneralesService, private modal: NgbModal, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
 
    this.getMetodos();

    this.frmNew = this.formBuilder.group({
      nombre: ["", Validators.required],
      titulo1: ["", Validators.required],
      titulo2: [""],
      descripcion1: ["", Validators.required],
      descripcion2: [""],
    });

  }

  openEdit(modal: any, id, nombre, titulo1, titulo2, descripcion1, descripcion2){
    this.modal.open(modal, { size: "xl" });
    this.frmEdit = this.formBuilder.group({
      id_metodopago: [id],
      nombre: [nombre, Validators.required],
      titulo1: [titulo1, Validators.required],
      titulo2: [titulo2],
      descripcion1: [descripcion1, Validators.required],
      descripcion2: [descripcion2],
    });
  }

  openModalNew(modal: any) {
    this.frmNew.reset();
    if (this.metodos.length >= 12) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Solo se permiten como máximo 12 metodos de pago',
      })
    }else{
      this.modal.open(modal, { size: "xl" });
    }
   }

  edit() {
    this.generalesService.editMetodo(this.frmEdit.value).subscribe((resp: any) => {
      if (resp == true) {
        Swal.fire({
          icon: 'success',
          title: 'Éxito',
          text: 'Método de pago editado con éxito',
        })
        this.modal.dismissAll();
        this.getMetodos();
      }
    }, (error: any) => { console.log("Error al editar un nuevo metodo", error); })
  }

  new() {
    if (this.metodos.length >= 12) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Solo se permiten como máximo 12 metodos de pago',
      })
    } else {
      this.generalesService.setMetodo(this.frmNew.value).subscribe((resp: any) => {
        if (resp == true) {
          Swal.fire({
            icon: 'success',
            title: 'Éxito',
            text: 'Método de pago generado con éxito',
          })
          this.modal.dismissAll();
          this.getMetodos();
        }
      }, (error: any) => { console.log("Error al generar un nuevo metodo", error); })
    }
  }

  getMetodos(){
    this.generalesService.getAllMetodos().subscribe((resp: any) => {
      this.metodos = resp;
    }, (error: any) => { console.log("Error al obtener los métodos de pago: ", error); })

  }

  delete(id: number,nombre: string ){
    Swal.fire({
      title: '¿Seguro que deseas continuar?',
      html: "Está eliminando el método de pago: <b>" + nombre + "</b>",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        this.generalesService.deleteMetodo(id).subscribe((resp: any) => {
          if (resp == true) {
            Swal.fire({
              icon: 'success',
              title: 'Éxito',
              text: 'Eliminado con éxito',
            })
            this.modal.dismissAll();
            this.getMetodos();
          }
        }, (error: any) => { console.log("Error al eliminar: ", error); })
      }
    })
  }


}
