import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RifasService } from 'src/app/services/rifas.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-activa',
  templateUrl: './activa.component.html',
  styleUrls: ['./activa.component.scss']
})
export class ActivaComponent implements OnInit {

  numbers: any;
  rifa: any;
  countValidTicketsI: number = 0;
  tempNumbers: any;
  seleccionados = new Array();
  winnerNum: number;
  frmEdit: FormGroup;
  numbersEdit: boolean = true;
  winnumbers = new Array();
  imgSrc: string = "";
  fileToUpload: any;

  constructor(private rifasService: RifasService, private modal: NgbModal, private formBuilder: FormBuilder) { }

  ngOnInit(): void {


    //Obtiene la rifa y los numeros
    this.rifasService.getRifa().subscribe({
      next: resp => {
        this.rifa = resp
        this.imgSrc = environment.apiUrl;
        this.imgSrc = this.imgSrc.slice(0, -4);
        this.imgSrc = this.imgSrc + "api/assets/imgs/" + this.rifa.id_rifa + "/imagensorteo.png?random+\=" + Math.random();
        this.rifasService.getWinnersData(this.rifa.id_rifa).subscribe((resp: any) => {
          this.winnumbers = resp;
        }, (error: any) => { console.log("Error al obtener los numeros ganadores: ", error); })
        this.rifasService.getNumbers(this.rifa.id_rifa).subscribe({
          next: resp => {
            this.numbers = resp;
            this.numbers.forEach((element: any) => {
              if (element.id_estadonumero == 1) {
                this.countValidTicketsI = this.countValidTicketsI + 1;
              } else {
                this.numbersEdit = false;
              }
            });
            //Formulario para editar la rifa
            this.frmEdit = this.formBuilder.group({
              premio: [this.rifa.premio, Validators.required],
              descripcion: [this.rifa.descripcion, Validators.required],
              fechainicio: [this.rifa.fechainicio],
              fechafin: [this.rifa.fechafin],
              cantnumbers: [this.numbers.length, Validators.required],
              numbersEdit: [this.numbersEdit, Validators.required]
            });
          },
          error: error => console.log("Error al obtener los numeros: ", error),
        })
      },
      error: error => console.log("Error al obtener la rifa: ", error),
    });




  }


  //Elimina un numero de los seleccionados
  remove(number: number) {
    let index = this.seleccionados.findIndex((d: any) => d.numero === number);
    this.numbers.push(this.seleccionados[index]);
    this.seleccionados.splice(index, 1);
    //Ordena ascendente
    this.numbers.sort((a: { numero: number; }, b: { numero: number; }) => {
      return a.numero - b.numero;
    });


  }

  //Selecciona un numero
  select(number: number, estado: number) {
    if (estado == 1) {
      let index = this.numbers.findIndex((d: any) => d.numero === number);
      this.seleccionados.push(this.numbers[index]);
      this.numbers.splice(index, 1);
      //Ordena ascendente
      this.seleccionados.sort((a: { numero: number; }, b: { numero: number; }) => {
        return a.numero - b.numero;
      });
    }
  }

  openModal(modal: any, size: string) {
    this.modal.open(modal, { size: size });
  }

  onFileChange(fileChangeEvent: any) {
    this.fileToUpload = fileChangeEvent.target.files[0];
    this.rifasService.addImage(this.fileToUpload, this.rifa.id_rifa).subscribe((resp: any) => {
      if (resp == true) {
        console.log("Imagen cargada con éxito");
        this.imgSrc = this.imgSrc + "api/assets/imgs/" + this.rifa.id_rifa + "/imagensorteo.png?random+\=" + Math.random();
      }
    }, (error: any) => { console.log("Error al subir la imagen: ", error); });
  }

  save() {
    Swal.fire({
      title: '¿Seguro que deseas continuar?',
      html: "Está guardando el número: <b>" + this.winnerNum + "</b> como ganador de la rifa",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        this.rifasService.saveNum(this.winnerNum, this.rifa.id_rifa).subscribe((resp: any) => {
          if (resp == true) {
            Swal.fire({
              icon: 'success',
              title: 'Éxito',
              text: 'Guardado con éxito',
            })
            this.rifasService.getWinnersData(this.rifa.id_rifa).subscribe((resp: any) => {
              this.winnumbers = resp;
            }, (error: any) => { console.log("Error al obtener los numeros ganadores: ", error); })
            this.modal.dismissAll();
            this.winnerNum = null;
          }
        }, (error: any) => { console.log("Error al guardar el numero ganador: ", error); })

      }
    })


  }

  edit() {
    if (this.frmEdit.value.fechainicio > this.frmEdit.value.fechafin) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'La fecha fin debe ser mayor a la fecha de inicio',
      })
    } else {

      this.rifasService.editRifa(this.frmEdit.value, this.rifa.id_rifa).subscribe({
        next: resp => {
          if (resp == true) {
            window.location.reload();
          }
        },
        error: error => console.log("Error al editar la rifa: ", error)
      });
    }
  }

  close() {
    Swal.fire({
      title: '¿Seguro que deseas cerrar esta rifa?',
      html: "<b>Esta acción no es revertible!</b>",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        this.rifasService.closeRifa(this.rifa.id_rifa).subscribe({
          next: resp => {
            if (resp == true) {
              window.location.reload();
            }
          },
          error: error => console.log("Error al cerrar la rifa: ", error)
        });
      }
    })
  }



}
