<div class="wrapper">
    <app-sidebar></app-sidebar>

    <!-- Page Content  -->
    <div id="content-header">
        <app-header></app-header>
        <div id="content">
            <div class="row">
                <div class="col-sm">
                    <div class="card mx-auto">
                        <div class="card-body">
                            <h5 class="card-title text-center">Panel Administrador</h5>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" style="margin-top: 20px;">
                <div class="col-sm">
                    <div class="card mx-auto" routerLink="/rifas/activa" class="verde">
                        <div class="card-body">
                            <h5 class="card-title text-center" style="color:#ffffff; font-weight: 600;">RIFA
                                ACTIVA<br /><i class="fa fa-ticket" style="margin-top: 10px;"></i></h5>
                        </div>
                    </div>
                </div>
                <div class="col-sm">
                    <div class="card mx-auto" routerLink="/rifas/nueva" class="azul">
                        <div class="card-body">
                            <h5 class="card-title text-center" style="color:#ffffff; font-weight: 600;">
                                NUEVA RIFA<br /><i class="fa fa-plus" style="margin-top: 10px;"></i></h5>
                        </div>
                    </div>
                </div>
                <div class="col-sm">
                    <div class="card mx-auto" routerLink="/rifas/lista" class="rojo">
                        <div class="card-body">
                            <h5 class="card-title text-center" style="color:#ffffff;font-weight: 600;">RIFAS
                                COMPLETADAS<br /><i class="fa fa-check" style="margin-top: 10px;"></i></h5>
                        </div>
                    </div>
                </div>
                <div class="col-sm">
                    <div class="card mx-auto" routerLink="/usuarios/lista" class="violeta">
                        <div class="card-body">
                            <h5 class="card-title text-center" style="color:#ffffff; font-weight: 600;">
                                PARTICIPANTES<br /><i class="fa fa-users" style="margin-top: 10px;"></i></h5>
                        </div>
                    </div>
                </div>
            </div>
            <div class="estadisticas mt-5" *ngIf="rifa">
                <h2 class="text-center" style="margin-top:35px;">Estadísticas</h2>
                <div class="row mt-5">
                    <div class="col-sm">
                        <div class="info-disponibles text-center mt-2">
                            <span class="dot"></span>
                            <span><b>Disponibles</b></span>
                        </div>
                        <div class="disponibles mt-3">
                            <div class="count"> {{countValidTicketsD}}</div>
                        </div>
                    </div>
                    <div class="col-sm">
                        <div class="info-apartados text-center mt-2">
                            <span class="dot"></span>
                            <span><b>Apartados</b></span>
                        </div>
                        <div class="apartados mt-3">
                            <div class="count">{{countValidTicketsA}}</div>
                        </div>
                    </div>
                    <div class="col-sm">
                        <div class="info-pagados text-center mt-2">
                            <span class="dot"></span>
                            <span><b>Pagados</b></span>
                        </div>
                        <div class="pagados mt-3">
                            <div class="count">{{countValidTicketsP}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>