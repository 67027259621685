import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GeneralesService {

  constructor(private http: HttpClient) { }

  getUserIdByDocument() {
    return this.http.get(environment.apiUrl + 'getUserIdByDocument');
  }

  getUserData() {
    return this.http.get(environment.apiUrl + 'getUserData');
  }

  changePassword(data: any) {
    return this.http.post(environment.apiUrl + 'changePassword', data);
  }

  getUsers() {
    return this.http.get(environment.apiUrl + 'getAllUsers');
  }

  getAllMetodos() {
    return this.http.get(environment.apiUrl + 'getAllMetodos');
  }

  setMetodo(data: any) {
    return this.http.post(environment.apiUrl + 'setMetodo', data);
  }

  editMetodo(data: any) {
    return this.http.post(environment.apiUrl + 'editMetodo', data);
  }

  deleteMetodo(id: any) {
    let data = {
      "id_metodopago": id
    }
    return this.http.post(environment.apiUrl + 'deleteMetodo', data);
  }
}
