<div class="wrapper">
    <app-sidebar></app-sidebar>

    <!-- Page Content  -->
    <div id="content-header">
        <app-header></app-header>
        <div id="content">
            <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                class="table table-striped table-bordered row-border hover">
                <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Apellido</th>
                        <th>WhatsApp</th>
                        <th>Estado</th>
                        <th>Numeros</th>
                        <th>Fecha Apartado</th>
                        <th>Pago</th>
                        <th>Cancelar</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let m of users">
                        <td>{{m.nombre}}</td>
                        <td>{{m.apellido}}</td>
                        <td style="text-align: center; font-size: 18px; font-weight:600"><i class="fa-brands fa-whatsapp"
                            style="cursor:pointer" title="Enviar Mensaje" style="color:#000; cursor:pointer" (click)="whatsapp(m.whatsapp)"></i></td>
                        <td>{{m.ciudad}}</td>
                        <td>{{m.numeros}}</td>
                        <td>{{m.fechaApartado | date: 'dd/MM/yyyy H:mm' }}</td>
                        <ng-container *ngIf="m.id_estadonumero === '3'; then pagado; else nopagado">
                        </ng-container>
                        <ng-template #pagado>
                            <td style="color:green; font-weight:600">
                                Pagado <i class="fa-solid fa-arrow-rotate-left"
                                style="cursor:pointer" title="Revertir" style="color:#000; cursor:pointer" (click)="unpay(m.id_participante, m.id_rifa, m.numeros)"></i>
                            </td>
                        </ng-template>
                        <ng-template #nopagado>
                            <td style="color:brown; font-weight: 600;">
                                Pendiente <i class="fa-solid fa-money-bill"
                                style="cursor:pointer; color:#000" title="Marcar como pagado" (click)="pay(m.id_participante, m.id_rifa, m.numeros, m.premio, m.whatsapp)"></i>
                            </td>
                        </ng-template>
                        <td class="text-center" ><i
                                class="fa-solid fa-ban" *ngIf="m.id_estadonumero !== '3'" (click)="cancel(m.id_participante, m.numeros, m.id_rifa)" style="cursor:pointer"></i></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>