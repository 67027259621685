<!-- Sidebar  -->
<nav id="sidebar">
    <div class="sidebar-header text-center mb-5">
        <!--  <img src="../../../assets/imgs/logo.png" width="150">-->
    </div>

    <ul class="list-unstyled components">
        <li>
            <a href="index"><i class="fa fa-dashboard sidebar-icon"></i> Dashboard</a>
        </li>
        <li>
            <a href="#Rifas" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">
                <i class="fa-solid fa-sack-dollar sidebar-icon"></i> Rifas</a>
            <ul class="collapse list-unstyled" id="Rifas">
                <li>
                    <a href="rifas/activa">Activa</a>
                </li>
                <li>
                    <a href="rifas/nueva">Nueva</a>
                </li>
                <li>
                    <a href="rifas/lista">Completadas</a>
                </li>
            </ul>
        </li>
        <li>
            <a href="usuarios/lista"><i class="fa-solid fa-users sidebar-icon"></i> Participantes</a>
        </li>
        <li>
            <a href="metodosdepago/lista"><i class="fa-solid fa-money-bill sidebar-icon"></i> Métodos de pago</a>
        </li>
        <li>
            <a href="#MiCuenta" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">
                <i class="fa fa-user sidebar-icon"></i> Mi Cuenta</a>
            <ul class="collapse list-unstyled" id="MiCuenta">
                <li>
                    <a href="mi-cuenta/mi-perfil">Mi Perfil</a>
                </li>
            </ul>
        </li>
    </ul>

    <ul class="list-unstyled CTAs">
        <li>
            <a (click)="logout()" class="article">Cerrar sesión</a>
        </li>
    </ul>
</nav>