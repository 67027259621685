import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { GeneralesService } from 'src/app/services/generales.service';
import { RifasService } from 'src/app/services/rifas.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {


  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  users: any;

  constructor(private generalesService: GeneralesService, private rifasService: RifasService) { }

  ngOnInit(): void {
    
    //Cargar DataTable
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 25,
      language: {
        url: '//cdn.datatables.net/plug-ins/1.11.3/i18n/es_es.json'
      },
      dom: 'Bfrtip',
      // Configure the buttons
     };
    this.generalesService.getUsers().subscribe((resp: any) => {
      this.users = resp;
      this.dtTrigger.next();
    }, (error: any) => { console.log("Error al obtener la lista de usuarios: ", error); })
  }


  cancel(id_participante: number, numbers: string, id_rifa: number) {
    Swal.fire({
      title: '¿Seguro que deseas cancelar estos numeros?',
      html: "Los numeros son: <b>" + numbers + "</b> <br/><br/> <b>Esta acción no es revertible!</b>",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        this.rifasService.cancelNumbers(id_participante, numbers, id_rifa).subscribe((resp: any) => {
          if (resp == true) {
            Swal.fire(
              'Cancelados!',
              'Los numeros fueron cancelados y ahora estan disponibles nuevamente',
              'success'
            )
            this.rerender();
          }
        }, (error: any) => { console.log("Error al cancelar los numeros: ", error) })

      }
    })
  }

  rerender() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.generalesService.getUsers().subscribe((resp: any) => {
        this.users = resp;
        this.dtTrigger.next();
      }, (error: any) => { console.log("Error al obtener la lista de usuarios: ", error); })
    });
  }


  
  pay(id_participante: number, id_rifa: number, numeros: string, premio: string, whatsapp: string) {
    const codigoDePais = '+52';
    const numeroDeTelefono = encodeURIComponent(whatsapp); // Codificar el número de teléfono
    const emojiCorazon = encodeURIComponent('\u2764'); // Código Unicode para el emoji de corazón
    const emojiCaritaSonriente = encodeURIComponent('\uD83D\uDE0A'); // Código Unicode para el emoji de carita sonriente
  
    const mensaje = encodeURIComponent(
      `YA ESTÁ CONFIRMADO TU BOLETO\n\n` +
      `CHECA TU BOLETO AQUÍ \n\n` +
      `https://RifasETM.com/verificador\n\n` +
      `SIGUE A NUESTRA PÁGINA DE FACEBOOK \n` +
      `https://www.facebook.com/RfsElTioMartin/`
      );
    Swal.fire({
      title: '¿Seguro que deseas marcar estos numeros como pagados?',
      html: "Los numeros son: <b>" + numeros + "</b> <br/>",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        this.rifasService.payNumbers(id_participante, id_rifa).subscribe((resp: any) => {
          if (resp == true) {
             window.open(`https://wa.me/${codigoDePais}${numeroDeTelefono}?text=${emojiCorazon}${emojiCaritaSonriente}${mensaje}`)
            Swal.fire(
              'Éxito!',
              'Números marcados como pagados',
              'success'
            )
            this.rerender();
          }
        }, (error: any) => { console.log("Error al marcar los numeros como pagados: ", error) })

      }

    })
  }

  whatsapp(whatsapp: any){
    window.open("https://wa.me/"+"+52"+whatsapp+"?text=%C2%A1Hola%21%20%C2%A1Ya%20est%C3%A1%20confirmado%20tu%20boleto%21%20%F0%9F%8E%89%0A%0ACheca%20tu%20boleto%20aqu%C3%AD%3A%20%0Ahttps%3A%2F%2FRifasETM.com%2Fverificador%0A%0ASigue%20a%20nuestra%20p%C3%A1gina%20de%20Facebook%20%F0%9F%91%8D%3A%20%0Ahttps%3A%2F%2Fwww.facebook.com%2Fprofile.php%3Fid%3D100063763936826"
                                                       
    );
  }

  unpay(id_participante: number, id_rifa: number, numeros: string) {
    Swal.fire({
      title: '¿Seguro que deseas revertir este pago?',
      html: "Los numeros son: <b>" + numeros + "</b> <br/>",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        this.rifasService.unPayNumbers(id_participante, id_rifa).subscribe((resp: any) => {
          if (resp == true) {
            Swal.fire(
              'Éxito!',
              'Se ha revertido el pago',
              'success'
            )
            this.rerender();
          }
        }, (error: any) => { console.log("Error al revertir el pago de los numeros: ", error) })

      }

    })
  }
}






