<div class="wrapper">
    <app-sidebar></app-sidebar>

    <!-- Page Content  -->
    <div id="content-header">
        <app-header></app-header>
        <div *ngIf="rifa" id="content">
            <h3 class="text-center mb-3">RIFA ACTIVA</h3>
            <div class="row mb-3 mt-5 text-center">
                <div class="col">
                    <h3><b>Premio: </b></h3><span class="title-rifa">{{rifa?.premio}}</span>
                </div>
                <!--    <div class="col">
                    <h3><b>Fecha inicio:</b></h3> <h4>{{rifa?.fechainicio | date:
                        'dd/MM/yyyy'}}</h4>
                </div>
                <div class="col">
                    <h3><b>Fecha fin: </b></h3><h4>{{rifa?.fechafin | date: 'dd/MM/yyyy'}}</h4>
                </div>-->
            </div><br /><br />
            <div class="row mb-3 text-center" style="white-space: pre;">
                <div class="col">
                    <h3><b>Descripción: </b><br />
                        <h4>{{rifa?.descripcion}}</h4>
                    </h3>
                </div>
            </div><br /><br />
            <div class="row mb-3 text-center" style="white-space: pre;">
                <div class="col">
                    <h3><b>Números ganadores: </b><br /></h3>
                    <h4 *ngIf="winnumbers.length == 0">No hay números</h4>
                    <div *ngFor="let item of winnumbers">
                        <ng-container *ngIf="item.id_participante === null; then noganador; else ganador">
                        </ng-container>
                        <ng-template #noganador>
                            <h4>{{item.numeroganador}} - <span class="noganador">No hay ganador</span></h4>
                        </ng-template>
                        <ng-template #ganador>
                            <h4>{{item.numeroganador}} - <span class="ganador">{{item.nombre}} {{item.apellido}}</span>
                            </h4>
                        </ng-template>

                    </div>
                </div>
            </div>

            <div class="row m-5 numcontainer">
                <div class="col text-center">
                    <button class="btn btn-success" (click)="openModal(modalnumero, 'l')">Nro Ganador</button>
                </div>
                <div class="col text-center">
                    <button class="btn btn-danger" (click)="close()">Cerrar Rifa</button>
                </div>
                <div class="col text-center">
                    <button class="btn btn-warning btnwarning" (click)="openModal(modaleditar, 'xl')">Editar
                        Rifa</button>
                </div>
            </div>
        </div>
        <div *ngIf="!rifa" id="content">
            <h2 class="text-center mb-3" style="color:brown">No hay ninguna rifa activa</h2>
        </div>

    </div>
</div>


<!--Numero ganador-->
<ng-template #modalnumero let-modal>
    <div class="modal-header">
        <b>
            <div class="modal-title">GUARDAR NÚMERO GANADOR</div>
        </b>
        <button class="close" aria-label="close" type="button" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row" style="margin-top:10px;">
            <div class="col-sm text-center">
                <label class="form-label">Número</label>
                <input type="number" class="form-control" [(ngModel)]="winnerNum">
            </div>
        </div>
    </div>
    <div class="modal-footer ">
        <button type="button" (click)="save()" [disabled]="winnerNum < 1 || winnerNum > numbers.length"
            class="mx-auto btn btn-success">GUARDAR</button>
    </div>
</ng-template>


<!--Editar Rifa-->
<ng-template #modaleditar let-modal>
    <div class="modal-header">
        <b>
            <div class="modal-title">EDITAR RIFA</div>
        </b>
        <button class="close" aria-label="close" type="button" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="frmEdit">
            <div class="row">
                <div class="col">
                    <label class="form-label"><b>Premio: </b></label>
                    <textarea type="text" class="form-control" formControlName="premio"></textarea>
                </div>
                <div class="col">
                    <label class="form-label"><b>Descripción: </b></label>
                    <textarea type="text" class="form-control" formControlName="descripcion"></textarea>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <label class="form-label"><b>Imagen: </b></label><br />
                    <div class="content-img" (click)="file.click();">
                        <input type="file" accept="image/*" style="display:none;" id="file" #file
                            (change)="onFileChange($event)">
                        <img src="{{imgSrc}}" class="img-rifa"><br />
                    </div>
                </div>
                <div class="col">
                    <label class="form-label"><b>Cantidad de números: </b></label>
                    <input type="number" *ngIf="numbersEdit" class="form-control" formControlName="cantnumbers">
                    <input type="number" *ngIf="!numbersEdit" class="form-control" formControlName="cantnumbers"
                        readonly>
                </div>
            </div>
            <!--  <div class="row mt-3">
                <div class="col">
                    <label class="form-label"><b>Fecha inicio: </b></label>
                    <input type="date" class="form-control" formControlName="fechainicio">

                </div>
                <div class="col">
                    <label class="form-label"><b>Fecha fin: </b></label>
                    <input type="date" class="form-control" formControlName="fechafin">
                </div>
            </div>-->

            <div class="row">
                <div class="col text-center mt-5">
                    <button class="btn btn-success" [disabled]="!frmEdit.valid" (click)="edit()">GUARDAR</button>
                </div>
            </div>
        </form>
    </div>

</ng-template>