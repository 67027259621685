import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { SignInComponent } from './pages/authentication/sign-in/sign-in.component';
import { IndexComponent } from './pages/index/index.component';
import { MyProfileComponent } from './pages/my-account/my-profile/my-profile.component';
import { ListComponent } from './pages/users/list/list.component';
import { ListaComponent } from './pages/rifas/lista/lista.component';
import { NuevaComponent } from './pages/rifas/nueva/nueva.component';
import { ActivaComponent } from './pages/rifas/activa/activa.component';
import { MetodosComponent } from './pages/metodosdepago/metodos/metodos.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'authentication/sign-in',
    pathMatch: 'full'
  },
  //AUTHENTICATION
  { path: 'authentication/sign-in', component: SignInComponent },

  //PAGINAS
  { path: 'index', component: IndexComponent, canActivate: [AuthGuard] },

  //PARTICIPANTES
  { path: 'usuarios/lista', component: ListComponent, canActivate: [AuthGuard] },

  //MI CUENTA
  { path: 'mi-cuenta/mi-perfil', component: MyProfileComponent, canActivate: [AuthGuard] },

 //RIFAS
 { path: 'rifas/lista', component: ListaComponent, canActivate: [AuthGuard] },
 { path: 'rifas/nueva', component: NuevaComponent, canActivate: [AuthGuard] },
 { path: 'rifas/activa', component: ActivaComponent, canActivate: [AuthGuard] },

  //METODOS DE PAGO
  { path: 'metodosdepago/lista', component: MetodosComponent, canActivate: [AuthGuard] },
 ];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
